export const HomePageText ={
    subheader1: 'FEATURES',
    header: 'Let’s Check Our Featured Services',
    subheader:'We provide some of most seeked out solutions to solve complex business problem as Saas ',
   services_title1:'Web Development',
   services_title2:'Big Data Solutions',
   services_title3:'Devops',
   services_subheading:'',

   /* about_subheader:'ABOUT US',
    about_header:'Enjoy Full-Service Digital Marketing Expertise',
    about_des1:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
    about_des2:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi.',
    about_des3:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',

    serviceshome_subheader:'Our SERVICES',
    serviceshome_header:'Let’s Check Our Services',
    serviceshome_subheader1:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',


    services_box_title1:'Social Media Marketing',
    services_box_des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
    services_box_title2:'Content Marketing',
    services_box_title3:'Keyward Research',
    services_box_title4:'Skyrocketing Growth',
    services_box_title5:'PPC Advertising',
    services_box_title6:'Competitor Research',*/

}