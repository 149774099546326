export const ServicesText ={
   
    serviceshome_subheader:'Our SERVICES',
    serviceshome_header:'Let’s Check Our Services',
    serviceshome_subheader1:' Our range of services helps the clients to improve growth and competitiveness against the increasing business challenges',

    services_box_title1:'Web Development',
    services_box_des:'Provides Industry best resource for this solution.',
    services_box_title2:'Data Engineer',
    services_box_title3:'Quality Analysis',
    services_box_title4:'Devops',
    services_box_title5:'SRE',
    services_box_title6:'Business Analysts',

}